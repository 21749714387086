import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../Features/api/apiSlice";
import { concatPathWithParams } from "../../Features/api/apiHelper";

const municipalityDataAdapter = createEntityAdapter();

const initialState = municipalityDataAdapter.getInitialState();

export const municipalityDataAPISlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMVGraphData: builder.query({
            query: (request) =>
                concatPathWithParams(
                    `dsm/municipality-demand-mv/graph/`,
                    request.queryParams
                ),
            providesTags: ["municipality_raw_data"],
        }),
        getMVTableData: builder.query({
            query: (request) =>
                concatPathWithParams(
                    `dsm/municipality-demand-mv/table/`,
                    request.queryParams
                ),
            providesTags: ["municipality_raw_data"],
        }),
        getMuniReportView: builder.query({
            query: (request) =>
                concatPathWithParams(
                    `dsm/municipality/report/`,
                    request.queryParams
                ),
            providesTags: ["report"],
        }),
    }),
});

export const { useGetMVGraphDataQuery, useGetMVTableDataQuery , useGetMuniReportViewQuery} =
    municipalityDataAPISlice;
