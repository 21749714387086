import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import theme from "../../Global/theme";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { checkIsLoggedIn, checkIsLoginError, loginUser } from "./authSlice";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from '@mui/material/styles';
import { getUserGroups } from "./authSlice";
import { ReactComponent as SvgFile } from '../../Global/images/tjommie.svg';

export default function LogIn(props) {

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedin = useSelector(checkIsLoggedIn);
  const isLoginError = useSelector(checkIsLoginError)
  const user_groups = useSelector(getUserGroups);

  let password_reset_url = `${process.env.REACT_APP_LOGIN}/auth/password_reset/`;

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let email = data.get("email");
    let password = data.get("password");

    dispatch(loginUser({ email, password }));
  };

  useEffect(() => {
    if (isLoggedin) {
      if (user_groups.includes('dsm_qe')) {
        navigate('/qe/')
      }
      else if (user_groups.includes('dsm_installer') || user_groups.includes('neura_ims_admin')) {
        navigate('/installer/')
      } else if (user_groups.includes('dsm_operator') || user_groups.includes('dsm_operator_view_only')) {
        navigate('/')
      } else if (user_groups.includes('dsm_municipality')) {
        navigate('/municipality/')
      } else {
        navigate('/')
      }
    }
  }, [isLoggedin, isLoginError]);

  return (
    <>
      <div className="center-login">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Card
              sx={{ minHeight: '250px', }}
            >
              <CardContent>

                <Box
                  sx={{

                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                
                  <SvgFile/>
                  <br /><br />
             
                  <Box component="form" onSubmit={handleSubmit} noValidate>
                    <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                    />
                    <Collapse in={isLoginError}>
                      <Alert action={<IconButton aria-label="close" color="inherit" size="small"></IconButton>} sx={{ mb: 2 }} severity="error">
                        Invalid email or password!
                      </Alert>
                    </Collapse>
                    <br />
                    <Button type="submit" fullWidth variant="contained" sx={theme.mainButton.root} >
                      Sign In
                    </Button>
                    <br /> <br />
                    <a href={password_reset_url} color="#fff">
                      Forgot Password?
                    </a>
                  </Box>
                </Box>
               
              </CardContent>
            </Card>
          </Container>

        </ThemeProvider>
      </div>
    </>
  );
}
