import Grid from "@mui/material/Grid";
import OpperatorDashboardGraph from "./OpperatorDashboardGraph";
import OperatorDashboardDatePicker from "./OperatorDashboardDatePicker";
import { useState } from "react";
import dayjs from "dayjs";
import Card from "@mui/material/Card";

const styles = {
  container: {
    margin: "0 auto",
    padding: "0 16px",
    maxWidth: "1350px",
    marginTop: 70,
  },
};

export default function OperatorDashboardPage() {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [startDate, setStartDate] = useState(dayjs().subtract(1, "day"));
  const [endDate, setEndDate] = useState(dayjs());

  const request = {
    queryParams: {
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      userTimezone: userTimezone,
    },
  };
  return (
    <>
      <Grid
        container
        style={{
          marginTop: "2.5em",
          padding: "2em",
          maxWidth: "1700px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Card
          sx={{
            borderRadius: "12px",
            backgroundColor: "#fff",
            mb: 4,
            width: "100%",
          }}
        >
          <Grid item xs={12}>
            <OperatorDashboardDatePicker
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
            />
          </Grid>
        </Card>
      </Grid>
      <Grid style={styles.container}>
        <Grid
          sx={{ marginTop: "20px" }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <OpperatorDashboardGraph request={request}/>
        </Grid>
      </Grid>
    </>
  );
}
