import * as React from "react";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { setlogo, setnavHeading } from "./Nav/navSlice";
import { useGetQEJobCardHistoryQuery } from "../api/apiSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useLocation } from "react-router-dom";
import { Avatar } from "@mui/material";
import Button from "@mui/material/Button";
import {
  useDownloadInstallationImagesMutation,
  useDownloadDigitalCOCMutation, useAddChangeLogMutation
} from "../api/apiSlice";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  showError,
  showSuccess,
} from "../../Global/notifications/notificationsSlice";
import { useNavigate } from "react-router-dom";
import { useEditJobCardMutation } from "../api/apiSlice";
import { useSelector } from "react-redux";

import { getUserName } from "../Authentication/authSlice";
import { useGetPendingJobCardsQuery } from "../api/apiSlice";
import { useParams } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
export default function InstallDetailsWrapper() {
  const { pageName, id } = useParams();
  const { data: pendingJobCards, isSuccess: isPendingSuccess } =
    useGetPendingJobCardsQuery();
  const { data: historyJobCards, isSuccess: isHistorySuccess } =
    useGetQEJobCardHistoryQuery();

  const job_card_data =
    pageName === "history" ? historyJobCards : pendingJobCards;
  const isSuccess =
    pageName === "history" ? isHistorySuccess : isPendingSuccess;

  if (isSuccess && job_card_data) {
    const job_card_data_for_id = job_card_data.job_cards.find(
      (item) => item.id === parseInt(id, 10)
    );
    return (
      <InstallDetails
        job_card_data={job_card_data_for_id}
        pageName={pageName}
      />
    );
  } else return <></>;
}

function InstallDetails({ job_card_data, pageName }) {
  const dispatch = useDispatch();
  dispatch(setnavHeading("Installation Details"));
  dispatch(setlogo(false));

  return (
    <>
      <Helmet>
        <style>{"body { background: #FFF1D7; }"}</style>
      </Helmet>

      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        sx={{ mt: 10 }}
      >
        <InstallType data={job_card_data} />
        <SimImsiCard data={job_card_data} />
        <Address data={job_card_data} />
        <Geyser data={job_card_data} />
        <ReferenceNumber data={job_card_data} />
        <Checkboxes data={job_card_data} />
        <InstalledBy data={job_card_data} />
        {pageName === "pending-installs" ? (
          <ImagesForApproval data={job_card_data} />
        ) : (
          <>
            <DownloadImages data={job_card_data} />
            <DownloadCOC data={job_card_data} />
          </>
        )}
      </Grid>
    </>
  );
}
function InstallType({ data }) {
  const install_type =
    data.install_state == "UN"
      ? "Uninstall"
      : data.install_state == "RE"
      ? "Replacement"
      : "New installation";
  return (
    <>
      <Grid item xs={0.25} />
      <Grid item xs={11.5} key={data.id}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
        >
          <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }}>
            <Typography sx={{ color: "grey", fontSize: "12px" }}>
              Install Type
            </Typography>
            <Typography sx={{ color: "black" }}>{install_type}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={0.25} />
    </>
  );
}

function SimImsiCard({ data }) {
  return (
    <>
      <Grid item xs={0.25} />
      <Grid item xs={11.5} key={data.id}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            p: 2,
            mb: 2,
            cursor: "pointer",
            // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }}>
            <Typography sx={{ color: "grey", fontSize: "12px" }}>
              Serial number
            </Typography>
            <Typography sx={{ color: "black" }}>
              {data.serial_number}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }}>
            <Typography sx={{ color: "grey", fontSize: "12px" }}>
              IMSI number
            </Typography>
            <Typography sx={{ color: "black" }}>{data.imsi_number}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={0.25} />
    </>
  );
}

function Address({ data }) {
  return (
    <>
      <Grid item xs={0.25} />
      <Grid item xs={11.5} key={data.id}>
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          // justifyContent="center"
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            p: 2,
            mb: 2,
            cursor: "pointer",
            // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography sx={{ color: "grey", fontSize: "12px" }}>
            Address
          </Typography>

          <Typography sx={{ color: "black" }}>{data.address}</Typography>
        </Box>
      </Grid>
      <Grid item xs={0.25} />
    </>
  );
}

function Geyser({ data }) {
  return (
    <>
      <Grid item xs={0.25} />
      <Grid item xs={11.5} key={data.id}>
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            p: 2,
            mb: 2,
            cursor: "pointer",
            // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography sx={{ color: "grey", fontSize: "12px" }}>
            Geyser Orientation
          </Typography>

          <Typography sx={{ color: "black" }}>
            {data.geyser_orientation}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: "12px", mt: 1.5 }}>
            Geyser Type
          </Typography>

          <Typography sx={{ color: "black" }}>{data.geyser_type}</Typography>
        </Box>
      </Grid>
      <Grid item xs={0.25} />
    </>
  );
}

function ReferenceNumber({ data }) {
  return (
    <>
      <Grid item xs={0.25} />
      <Grid item xs={11.5} key={data.id}>
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          // justifyContent="center"
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            p: 2,
            mb: 2,
            cursor: "pointer",
            // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography sx={{ color: "grey", fontSize: "12px" }}>
            Install reference number
          </Typography>

          <Typography sx={{ color: "black" }}>{data.reference_id}</Typography>
        </Box>
      </Grid>
      <Grid item xs={0.25} />
    </>
  );
}

function Checkboxes({ data }) {
  return (
    <>
      <Grid item xs={0.25} />
      <Grid item xs={11.5} key={data.id}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            p: 2,
            mb: 2,
            cursor: "pointer",
            // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid container>
            <Grid item xs={8}>
              <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }}>
                <Typography sx={{ color: "grey", fontSize: "12px" }}>
                  Customer Controlled Breaker
                </Typography>
                {data.breaker_control ? (
                  <CheckCircleIcon sx={{ color: "#000", mt: 1 }} />
                ) : (
                  <CancelIcon sx={{ color: "#000", mt: 1 }} />
                )}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }}>
                <Typography sx={{ color: "grey", fontSize: "12px" }}>
                  Timer installed
                </Typography>
                {data.timer_installed ? (
                  <CheckCircleIcon sx={{ color: "#000", mt: 1 }} />
                ) : (
                  <CancelIcon sx={{ color: "#000", mt: 1 }} />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }}>
                <Typography sx={{ color: "grey", fontSize: "12px" }}>
                  Solar Geyser
                </Typography>
                {data.solar_geyser ? (
                  <CheckCircleIcon sx={{ color: "#000", mt: 1 }} />
                ) : (
                  <CancelIcon sx={{ color: "#000", mt: 1 }} />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }}>
                <Typography sx={{ color: "grey", fontSize: "12px" }}>
                  Solar PV Installed
                </Typography>
                {data.solar_pv ? (
                  <CheckCircleIcon sx={{ color: "#000", mt: 1 }} />
                ) : (
                  <CancelIcon sx={{ color: "#000", mt: 1 }} />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={0.25} />
    </>
  );
}

function InstalledBy({ data }) {
  const initials = data.installer_name
    .split(" ")
    .map((name) => name[0])
    .join("");
  return (
    <>
      <Grid item xs={0.25} />
      <Grid item xs={11.5} key={data.id}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            p: 2,
            mb: 2,
            cursor: "pointer",
            // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid container>
            <Grid item xs={2}>
              <Avatar sx={{ bgcolor: "lightgrey" }}>{initials}</Avatar>
            </Grid>
            <Grid item xs={10}>
              <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }}>
                <Typography sx={{ color: "grey", fontSize: "12px" }}>
                  Installed by:
                </Typography>
                <Typography sx={{ color: "black" }}>
                  {data.installer_name}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
}

function ImagesForApproval({ data }) {
  return (
    <>
      <Grid item xs={0.5} />
      <Grid item xs={11} key={data.id}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <Typography
            sx={{ color: "grey", fontSize: "12px", textAlign: "center" }}
          >
            Work Area Before Install
          </Typography>
          <img
            src={data.install_photo_before}
            alt="Before Work Area"
            style={{ maxWidth: "100%", height: "auto", borderRadius: "5px" }}
          />
        </Box>
      </Grid>
      <Grid item xs={0.5} />
      <Grid item xs={0.5} />
      <Grid item xs={11} key={data.id}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <Typography
            sx={{ color: "grey", fontSize: "12px", textAlign: "center" }}
          >
            DB Board Cover On Before Install
          </Typography>
          <img
            src={data.before_photo_on}
            alt="Before Photo On"
            style={{ maxWidth: "100%", height: "auto", borderRadius: "5px" }}
          />
        </Box>
      </Grid>
      <Grid item xs={0.5} />
      <Grid item xs={0.5} />
      <Grid item xs={11} key={data.id}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <Typography
            sx={{ color: "grey", fontSize: "12px", textAlign: "center" }}
          >
            DB Board Cover Off Before Install
          </Typography>
          <img
            src={data.before_photo_off}
            alt="Before Photo Off"
            style={{ maxWidth: "100%", height: "auto", borderRadius: "5px" }}
          />
        </Box>
      </Grid>
      <Grid item xs={0.5} />
      <Grid item xs={0.5} />
      <Grid item xs={11} key={data.id}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <Typography
            sx={{ color: "grey", fontSize: "12px", textAlign: "center" }}
          >
            DB Board Cover On After Install
          </Typography>
          <img
            src={data.after_photo_on}
            alt="After Photo On"
            style={{ maxWidth: "100%", height: "auto", borderRadius: "5px" }}
          />
        </Box>
      </Grid>
      <Grid item xs={0.5} />
      <Grid item xs={0.5} />
      <Grid item xs={11} key={data.id}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <Typography
            sx={{ color: "grey", fontSize: "12px", textAlign: "center" }}
          >
            DB Board Cover Off After Install
          </Typography>
          <img
            src={data.after_photo_off}
            alt="After Photo Off"
            style={{ maxWidth: "100%", height: "auto", borderRadius: "5px" }}
          />
        </Box>
      </Grid>
      <Grid item xs={0.5} />
      <Grid item xs={0.5} />
      <Grid item xs={11} key={data.id}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <Typography
            sx={{ color: "grey", fontSize: "12px", textAlign: "center" }}
          >
            Work Area After Install
          </Typography>
          <img
            src={data.install_photo_after}
            alt="After Work Area"
            style={{ maxWidth: "100%", height: "auto", borderRadius: "5px" }}
          />
        </Box>
      </Grid>
      <Grid item xs={0.5} />
      <ApproveDeclineButtons data={data} />
    </>
  );
}

function ApproveDeclineButtons({ data }) {
  const [openDeclineDialog, setOpenDeclineDialog] = useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);

  return (
    <>
      <Grid item xs={0.5} />
      <Grid item xs={11} align="center">
        <Box display="flex" justifyContent="center" gap={2} sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            sx={{ color: "#000", backgroundColor: "#fff" }}
            startIcon={<CancelIcon />}
            onClick={() => setOpenDeclineDialog(true)}
          >
            Decline
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "#000",
              backgroundColor: "#FDB940",
              "&:active": { backgroundColor: "#FDB940" },
              "&:focus": { backgroundColor: "#FDB940", outline: "none" },
            }}
            startIcon={<CheckCircleIcon />}
            onClick={() => setOpenApproveDialog(true)}
          >
            Approve
          </Button>
        </Box>
      </Grid>
      <Grid item xs={0.5} />

      <DeclineDialog
        openDeclineDialog={openDeclineDialog}
        setOpenDeclineDialog={setOpenDeclineDialog}
        data={data}
      />
      <ApproveDialogs
        openApproveDialog={openApproveDialog}
        setOpenApproveDialog={setOpenApproveDialog}
        data={data}
      />
    </>
  );
}

function DeclineDialog({ openDeclineDialog, setOpenDeclineDialog, data }) {
  const [declineReason, setDeclineReason] = useState("");
  const [resumeStep, setResumeStep] = useState("");
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user_name = useSelector(getUserName);

  const [
    editJobCard,
    { isSuccess: isEditJobCardSuccess, isError: isEditJobCardError },
  ] = useEditJobCardMutation();

   const [
    addChangeLog,
    {
      isSuccess: addChangeLogSuccess,
      isError: isaddChangeLogError,
      error: addChangeLogError,
    },
  ] = useAddChangeLogMutation();

  const handleDecline = () => {
    
      const body = {
        serial_number: data.install_state=="UN" ? data.archived_serial_number : data.serial_number,
        change:"QE declined the job, installer will be notified to REDO",
      };
      addChangeLog({ body: body });
 
  };


  React.useEffect(() => {
    if (addChangeLogSuccess) {
      const body = {
        serial_number: data.serial_number,
        imsi_number: data.imsi_number,
        reference_id: data.reference_id,
        qe_job_state: "Declined",
        qe_name: user_name,
        qe_decline_reason: declineReason,
        qe_decline_resume_from: resumeStep,
        state: "REDO",
      };
      editJobCard({ body });
      setOpenDeclineDialog(false);
    }
  }, [addChangeLogSuccess, ]);
  

  React.useEffect(() => {
    if (isEditJobCardSuccess) {
      navigate(-1);
      dispatch(showSuccess("Action completed successfully"));
    }
  }, [isEditJobCardSuccess, navigate, dispatch]);

  React.useEffect(() => {
    if (isEditJobCardError) {
      dispatch(showError("Error"));
    }
  }, [isEditJobCardError, dispatch]);
  const statusChoices =
    data.install_state == "NEW"
      ? ([
          { value: "QRC", label: "QR Code Scan" },
          { value: "BEFWA", label: "Before Work Area Picture" },
          { value: "BEF", label: "Before Photos of DB" },
          { value: "AMP", label: "AMP Test" },
          { value: "TES", label: "Testing" },
          { value: "AFT", label: "After Photos of DB" },
          { value: "AFTWA", label: "After Work Area Picture" },
        ])
      : (data.install_state == "UN"
      ? [
          { value: "BEFWA", label: "Before Work Area Picture" },
          { value: "BEF", label: "Before Photos of DB" },
          { value: "UNI", label: "Uninstall Notes" },
          { value: "AFT", label: "After Photos of DB" },
          { value: "AFTWA", label: "After Work Area Picture" },
        ]
      : [
          { value: "DET", label: "Geyser Details" },
          { value: "AMP", label: "AMP Test" },
          { value: "TES", label: "Testing" },
          { value: "AFT", label: "After Photos of DB" },
          { value: "AFTWA", label: "After Work Area Picture" },
      ]);
  return (
    <Dialog
      open={openDeclineDialog}
      onClose={() => setOpenDeclineDialog(false)}
    >
      <DialogTitle>
        Install Declined
        <CancelIcon
          sx={{ float: "right", cursor: "pointer" }}
          onClick={() => setOpenDeclineDialog(false)}
        />
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Provide a reason"
          fullWidth
          margin="normal"
          value={declineReason}
          variant="standard"
          onChange={(e) => setDeclineReason(e.target.value)}
        />
        <TextField
          variant="standard"
          label="Resume from:"
          fullWidth
          margin="normal"
          select
          value={resumeStep}
          onChange={(e) => setResumeStep(e.target.value)}
        >
          {statusChoices.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDeclineDialog(false)} color="primary">
          Back
        </Button>
        <Button
          onClick={handleDecline}
          variant="contained"
          sx={{ backgroundColor: "#FDB940", color: "#000" }}
        >
          Send to Installer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ApproveDialogs({ openApproveDialog, setOpenApproveDialog, data }) {
  let navigate = useNavigate();
  const { pageName } = useParams();
  const handleApprove = () => {
    if(data.install_state=="UN"){
    navigate(
      `/QE/${pageName}/install/details/${data.id}/approve/${data.reference_id}/${data.archived_serial_number}/${data.archived_imsi_number}/${data.install_state}/`
    );
  }
  else{
    navigate(
      `/QE/${pageName}/install/details/${data.id}/approve/${data.reference_id}/${data.serial_number}/${data.imsi_number}/${data.install_state}/`
    );
  }
  };

  return (
    <Dialog
      open={openApproveDialog}
      onClose={() => setOpenApproveDialog(false)}
    >
      <DialogTitle>Approve and capture digital C.O.C.</DialogTitle>
      <DialogContent>
        <p> Please confirm</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenApproveDialog(false)} color="primary">
          Back
        </Button>
        <Button
          onClick={handleApprove}
          variant="contained"
          sx={{ backgroundColor: "#FDB940", color: "#000" }}
        >
          Capture COC
        </Button>
      </DialogActions>
    </Dialog>
  );
}
function DownloadImages({ data }) {
  const [
    downloadImages,
    {
      isSuccess: isDownloadSuccess,
      isError: isDownloadError,
      error: downloadError,
      data: downloadData,
    },
  ] = useDownloadInstallationImagesMutation();

  const downloadInstallationImages = () => {
    const request = { job_card_id: data?.id };

    downloadImages(request)
      .unwrap()
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `job_card_${data?.id}_images.zip`);

        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Download failed:", error);
        alert("Failed to download file. Please try again.");
      });
  };

  return (
    <>
      <Grid item xs={0.25} />
      <Grid item xs={11.5}>
        <Button
          startIcon={<ArrowDownwardIcon />}
          onClick={downloadInstallationImages}
        >
          DOWNLOAD INSTALLATION IMAGES
        </Button>
      </Grid>
    </>
  );
}
function DownloadCOC({ data }) {
  const [
    downloadImages,
    {
      isSuccess: isDownloadSuccess,
      isError: isDownloadError,
      error: downloadError,
      data: downloadData,
    },
  ] = useDownloadDigitalCOCMutation();

  const downloadInstallationImages = () => {
    const request = { job_card_id: data.id };

    downloadImages(request)
      .unwrap()
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `job_card_${data?.id}_digital_coc.zip`);

        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Download failed:", error);
        alert("Failed to download file. Please try again.");
      });
  };

  return (
    <>
      <Grid item xs={0.25} />
      <Grid item xs={11.5}>
        <Button
          startIcon={<ArrowDownwardIcon />}
          onClick={downloadInstallationImages}
        >
          DOWNLOAD COC
        </Button>
      </Grid>
    </>
  );
}