import * as React from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { setCurrentStep, setsteps } from "../Stepper/stepperSlice";
import { useDispatch } from "react-redux";
import NavbarStepper from "../Stepper/NavBarStepper";
import { useParams } from "react-router-dom";
import {
  useEditJobCardMutation,
  useGetUninstallJobCardQuery, useAddjobCardMutation, useUninstallSmartNodeMutation,
  useUninstallReplacementSmartNodeMutation, useAddChangeLogMutation,
} from "../../api/apiSlice";
import { useEffect } from "react";
import {
  showError,
} from "../../../Global/notifications/notificationsSlice";
import { skipToken } from "@reduxjs/toolkit/query/react";
import Typography from "@mui/material/Typography";

export default function QRScanUninstall() {
  const [
    editJobCard,
    {
      isSuccess: iseditJobCardSuccess,
      isError: iseditJobCardError,
      error: editJobCardError,
    },
  ] = useEditJobCardMutation();

  const [
    addChangeLog,
    {
      isSuccess: addChangeLogSuccess,
      isError: isaddChangeLogError,
      error: addChangeLogError,
    },
  ] = useAddChangeLogMutation();
  
  const [
    uninstallSmartnode,
    {
      isSuccess: uninstallSmartnodeSuccess,
      isError: isuninstallSmartnodeError,
      error: uninstallSmartnodeError,
    },
  ] = useUninstallSmartNodeMutation();

  const [
    uninstallReplacementSmartnode,
    {
      isSuccess: uninstallReplacementSmartnodeSuccess,
      isError: isuninstallReplacementSmartnodeError,
      error: uninstallReplacementSmartnodeError,
    },
  ] = useUninstallReplacementSmartNodeMutation();

  const [qrCodeData, setQRCodeData] = React.useState(null);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { install_state } = useParams();
  dispatch(setCurrentStep(0));


  const [
    addJobCard,
    {
      data: job_card_data,
      isSuccess: isAddJobCardSuccess,
      isError: isAddJobCardError,
      error: addJobCardError,
    },
  ] = useAddjobCardMutation();

 
  const {
    data: job_card,
    isSuccess: isGetJobCardSuccess,
    isError: isGetJobCardError,
    error: getSmartNodeError,
  } = useGetUninstallJobCardQuery(
    qrCodeData
      ? {
          serial_number: qrCodeData.serial_number,
          imsi_number: qrCodeData.imsi_number,
        }
      : skipToken
  );


  useEffect(() => {
    if (isGetJobCardError) {
      dispatch(showError(getSmartNodeError.data.error));
      setIsProcessing(false);
    }
  }, [isGetJobCardError, job_card]);


 

  useEffect(() => {
    if ( isGetJobCardSuccess && qrCodeData && !isProcessing) {
      const { serial_number, imsi_number } = qrCodeData;
      if(job_card.state=="COM" && install_state=="UN"){
        
          const body = {
            serial_number:serial_number,
            change:"Started uninstall from field",
          };
          addChangeLog({ body: body });
    
      }
      else if( job_card.state=="COM" && install_state=="RE"){
        const body = {
          serial_number:serial_number,
          change:"Uninstalled device from field to do a replacement",
        };
        addChangeLog({ body: body });
      }
      else{
        setIsProcessing(false);
        dispatch(showError("This device has not been installed"));
      }
    } 
  }, [isGetJobCardSuccess, qrCodeData]);

  useEffect(() => {
    if (addChangeLogSuccess && qrCodeData && !isProcessing) {
      const { serial_number, imsi_number } = qrCodeData;
    
      if(job_card.state=="COM"){
        const body = {
          address: job_card.address,
          geyser_name: job_card.geyser_name,
          customer_consent: job_card.customer_consent,
          geyser_type: job_card.geyser_type,
          geyser_orientation: job_card.geyser_orientation,
          breaker_control: job_card.breaker_control,
          solar_geyser: job_card.solar_geyser,
          solar_pv: job_card.solar_pv,
          ripple_replacement: job_card.ripple_replacement,
          timer_installed: job_card.timer_installed,
          aborted: false,
          state: "BEFWA",
          install_state: install_state,
          archived_serial_number:serial_number,
          archived_job_card_number: job_card.id,
          archived_imsi_number: imsi_number,
          smart_node: null,
        };
        setIsProcessing(true);
        addJobCard({body:body})
        
      }
      else{
        setIsProcessing(false);
        dispatch(showError("This device has not been installed"));
      }
    } 
  }, [addChangeLogSuccess, qrCodeData]);

  useEffect(() => {
    if (uninstallReplacementSmartnodeSuccess || uninstallSmartnodeSuccess) {
      setIsProcessing(false);
      if(job_card.state=="COM"){
        const body = {
          serial_number: job_card.serial_number,
          imsi_number: job_card.imsi_number,
          reference_id: job_card.reference_id,
          smart_node: null,  
        };
    
        editJobCard({ body: body });
      }
      else{
        dispatch(showError("This device has not been installed"));
      }
      
    } 
  }, [uninstallReplacementSmartnodeSuccess, uninstallSmartnodeSuccess]);


    useEffect(() => {
      if (isAddJobCardSuccess) {
        const { serial_number } = qrCodeData;
        const body = {
          serial_number:serial_number,
          archived_job_card_number: job_card.id,
        
        };
        if(install_state=="UN"){
        uninstallSmartnode({body:body});
        }
        else{
          uninstallReplacementSmartnode({body:body});
        }

      }

    }, [isAddJobCardSuccess, job_card, ]);

    useEffect(() => {
      if (uninstallSmartnodeSuccess || uninstallReplacementSmartnodeSuccess) {
  
        navigate(`/installer/`);
      }

    }, [uninstallSmartnodeSuccess, uninstallReplacementSmartnodeSuccess]);

    useEffect(() => {
      if (iseditJobCardError) {
        dispatch(showError(editJobCardError.data.error_message));
      }

    }, [iseditJobCardError, editJobCardError, dispatch]);

  return (
    <>
      <NavbarStepper />
      <Typography sx={{color:"grey", fontSize: "16px"}}>Scan QR Code of the device being uninstalled</Typography>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          align: "center",
          justifyContent: "center",
          marginTop: "-40px",
        }}
      >
       
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result, error) => {
            if (result) {
              const qr_code_data = extractDataFromUrl(result.text);
              setQRCodeData(qr_code_data);
            }
            if (error) {
              console.info(error);
            }
          }}
        />
      </div>
    </>
  );
}

function extractDataFromUrl(url) {
  const urlParts = url.split("/");
  if (urlParts.length < 7) {
    throw new Error(
      `Invalid URL format: Current Part length${urlParts.length}`
    );
  }
  return {
    serial_number: urlParts[5],
    imsi_number: urlParts[6],
  };
}
