import * as React from "react";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import theme from "../../Global/theme";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import LogOutDialog from "./LogoutDialog";
import Alert from "@mui/material/Alert";
import {
  useEditJobCardMutation,
  useEditIMSSmartnodeMutation,
  useGetJobCardStateQuery,
} from "../api/apiSlice";

export default function JobCompletePage() {
  let navigate = useNavigate();
  let { job_card_reference_id} = useParams();
  const { data: job_card_data, isSuccess: isGetJobCardStateSuccess } =
    useGetJobCardStateQuery();

  const [heading, setHeading] = React.useState("Job Reference Number");
  const [unInstall, setUninstall] = React.useState(false);
  useEffect(() => {
    if (isGetJobCardStateSuccess) {
      if (job_card_data.install_state === "UN") {
        setHeading("Uninstall Complete");
        setUninstall(true);
      }

      else if (job_card_data.install_state === "RE") {
        setHeading("Replacement Complete");
      }
    }
  }, [isGetJobCardStateSuccess]);
  const [editIMSSmartNode, { isSuccess: isEditIMSSmartNodeSuccess }] =
    useEditIMSSmartnodeMutation();

  const [
    editJobCard,
    { isSuccess: isEditJobCardSuccess, isLoading: isEditJobCardLoading },
  ] = useEditJobCardMutation();

  const handleCompleteJob = () => {
   
    if (job_card_data.install_state === "UN" || job_card_data.install_state === "RE") {
      const body = {
        serial_number: job_card_data.archived_serial_number,
        imsi_number: job_card_data.archived_imsi_number,
        status: "Collected",
        change: job_card_data.install_state=="UN" ? "Uninstall complete, device is collected, QE to approve" : "Replacement complete, device is collected"
      };
      editIMSSmartNode({ body: body });
    }
    else  if (job_card_data.install_state === "NEW") {
      const body = {
        serial_number: job_card_data.serial_number,
        imsi_number: job_card_data.imsi_number,
        change: "Installed in field, waiting for QE to approve"
      };
      editIMSSmartNode({ body: body });
    }
  };
 

  useEffect(() => {
    if (isEditIMSSmartNodeSuccess) {
      const body = {
        reference_id: job_card_reference_id,
        state: "QEP",
        qe_job_state: "Pending",
      };
      editJobCard({ body: body });
    }
  }, [isEditIMSSmartNodeSuccess]);

  useEffect(() => {
    if (isEditJobCardSuccess) {
      
      navigate(`/installer/`);
     
    }
  }, [isEditJobCardSuccess]);

  return (
    <>
      <Helmet>
        <style>{"body { background: #FDB940; }"}</style>
      </Helmet>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 5 }}
      >
        <Grid item xs={8} align="center" />
        <Grid item xs={3.5} align="right">
          <LogOutDialog />
        </Grid>
        <Grid item xs={0.5} align="right" />

        <Typography
          variant="h4"
          sx={{ mt: 20, mb: 5 }}
          style={{ fontSize: "40px", color: "white" }}
        >
          {heading}
        </Typography>
        <Grid item xs={12} align="center">
          <Typography
            style={{ fontSize: "24px", color: "black" }}
            sx={{ mb: 5 }}
          >
            REF : {job_card_reference_id}
          </Typography>
        </Grid>

        <Grid item xs={1} />
        {unInstall ? (
          <Grid item xs={10} align="center">
            <Alert severity="warning" sx={{ mt: 1, mb: 1 }}>
              Note: In order to complete the uninstall, click the button below
            </Alert>
            <Button
              style={theme.whiteButtonStyle.root}
              variant="outlined"
              onClick={handleCompleteJob}
            >
              COMPLETE
            </Button>
          </Grid>
        ) : (
          <Grid item xs={10} align="center">
            <Alert severity="warning" sx={{ mt: 1, mb: 1 }}>
              Note: In order to complete the Installation, click the button
              below
            </Alert>
            <Button
              style={theme.whiteButtonStyle.root}
              variant="outlined"
              onClick={handleCompleteJob}
            >
              COMPLETE JOB
            </Button>
          </Grid>
        )}
        <Grid item xs={1} />
      </Grid>
    </>
  );
}
