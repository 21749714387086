import * as React from "react";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { setlogo, setnavHeading } from "./Nav/navSlice";

import { useNavigate } from "react-router-dom";

import { useGetPendingJobCardsQuery } from "../api/apiSlice";

export default function PendingInstalls() {
  const dispatch = useDispatch();
    const navigate = useNavigate();

    const { data: job_card_data, isSuccess: isGetJobCardPendingStateSuccess } =
    useGetPendingJobCardsQuery();

  dispatch(setnavHeading("Pending installs"));
  dispatch(setlogo(false));
  

  if(isGetJobCardPendingStateSuccess){
    return (
      <>
        <Helmet>
          <style>{"body { background: #FFF1D7; }"}</style>
        </Helmet>

        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          sx={{ mt: 10 }}
        >
          <InstallsList data={job_card_data.job_cards} navigate={navigate}/>
        </Grid>
      </>
    );
  }
else return null;
}



function InstallsList({ data, navigate }) {
  if(data.length>0){
  return data.map((item) => (
    <>
      <Grid item xs={0.25} />
      <Grid item xs={11.5} key={item.id}  onClick= {() =>{navigate(`/qe/${'pending-installs'}/install/details/${item.id}`)}}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            p: 2,
            mb: 2,
            cursor: "pointer",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
         
        >
          <Typography sx={{ color: "black" }}>{item.address}</Typography>
          <IconButton>
            <NotificationsActiveIcon sx={{ color: "grey" }} />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={0.25} />
    </>
  ));
}
else return(
  <Typography sx={{ color: "black" ,textAlign: "center", width: "100%"}} >No Pending Installs</Typography>
)
}
