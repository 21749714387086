import * as React from "react";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
// import { setlogo, setnavHeading } from "./Nav/navSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Button from "@mui/material/Button";

import {
  useGetDeclinedJobCardsQuery,
  useEditRedoInstallMutation, useAddChangeLogMutation
} from "../api/apiSlice";

import { useState } from "react";
import { Nav } from "../QE/Nav/Elements";

import { useSelector } from "react-redux";

import "@fontsource/roboto/400.css";

function TopNav() {
  return (
    <Box sx={{ mb: 10, background: "#FDB940" }}>
      <Nav>
        <br />
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={2} align="center" sx={{ml:2}}>
            <Backbutton />
          </Grid>
          <Grid item xs={8} align="left" sx={{ color: "#fff" }}>
            Alerts
          </Grid>
        </Grid>
      </Nav>
    </Box>
  );
}

export default function Alerts() {
  const dispatch = useDispatch();

  const { data: job_card_data, isSuccess: isGetJobCardDeclinedSuccess } =
    useGetDeclinedJobCardsQuery();

  if (isGetJobCardDeclinedSuccess) {
    return (
      <>
        <Helmet>
          <style>{"body { background: #FDB940; }"}</style>
        </Helmet>

        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          sx={{ mt: 10 }}
        >
          <TopNav />
          <DeclinedList data={job_card_data.job_cards} />
        </Grid>
      </>
    );
  } else return null;
}

function DeclinedList({ data }) {
  const navigate = useNavigate();
  const [redoInstall, { isSuccess: isRedoSuccess }] =
    useEditRedoInstallMutation();
    const [id, setID] = React.useState(null);
   
    const [
      addChangeLog,
      {
        isSuccess: addChangeLogSuccess,
        isError: isaddChangeLogError,
        error: addChangeLogError,
      },
    ] = useAddChangeLogMutation();
   React.useEffect(() => {
      if (addChangeLogSuccess) {
        const body = {
          job_card_id: id,
        };
        redoInstall({ body });
      } 
    }, [addChangeLogSuccess]);
  const handleReinstall = (id, serial_number, archived_serial_number, installed_state) => {
   setID(id);
   const body = {
    serial_number:installed_state!=="UN" ? serial_number : archived_serial_number,
    change: installed_state!== "UN" ? "Installer is redoing declined install" :  "Installer is redoing declined uninstall",
  };
  addChangeLog({ body: body });
  };
  React.useEffect(() => {
    if (isRedoSuccess) {
      navigate("/installer/");
    }
  }, [isRedoSuccess]);

  if (data.length > 0) {
    return data.map((item) => (
      <>
        <Grid item xs={0.25} />
        <Grid item xs={11.5} key={item.id}>
          <Box
            display="flex"
            alignItems="left"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
              backgroundColor: "#FFF2DD",
              borderRadius: "5px",
              p: 2,
              mb: 2,
            }}
          >
            <Box display="flex" flexDirection="row" sx={{ flexGrow: 1 }}>
              <InfoOutlinedIcon />
              <Typography sx={{ color: "black", fontSize: "12px", ml: 2 }}>
                {item.installation_date}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" sx={{ flexGrow: 1 }}>
              <Typography sx={{ color: "black", fontSize: "18px", ml: 5 }}>
                Install Approval Declined : {item.address}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="row" sx={{ flexGrow: 1 }}>
              <Typography sx={{ color: "black", fontSize: "18px", ml: 5 }}>
                Reason : <br /> {item.qe_decline_reason}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
              <Button
                onClick={() => handleReinstall(item.id, item.serial_number, item.archived_serial_number, item.installed_state)}
                variant="contained"
                sx={{ backgroundColor: "#FDB940", color: "#000" }}
              >
                Reinstall
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={0.25} />
      </>
    ));
  } else
    return (
      <Grid container alignItems="center" sx={{ mt: 5 }}>
        {" "}
        <Grid irem xs={10} align="center">
          <Typography sx={{ fontSize: 24, color: "#000" }}>
            NO ALERTS
          </Typography>
        </Grid>{" "}
      </Grid>
    );
}

function Backbutton() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return <ArrowBackIcon onClick={handleBack} sx={{ color: "#fff" }} />;
}
