import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Typography, Chip } from "@mui/material";
import Button from "@mui/material/Button";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { getUserName } from "../Authentication/authSlice";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import HistoryIcon from "@mui/icons-material/History";
import { useDispatch } from "react-redux";
import { setlogo, setnavHeading } from "./Nav/navSlice";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { useGetPendingJobCardsQuery } from "../api/apiSlice";
import { useGetQEJobCardHistoryQuery } from "../api/apiSlice";


export default function QEDashboard() {
  let navigate = useNavigate();
  const dispatch = useDispatch();


  dispatch(setnavHeading("Tjommie QE App"));
  dispatch(setlogo(true));

  return (
    <>
      <Helmet>
        <style>{"body { background: #FFF1D7; }"}</style>
      </Helmet>

      <TopNames />
      <ClickableBoxes navigate={navigate}/>
      <NewInstallButton navigate={navigate}/>
    </>
  );
}

function TopNames() {
  const user_name = useSelector(getUserName);
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      sx={{ mt: 1 }}
    >
      <Grid item xs={6} align="left" sx={{ ml: 1 }}>
        <Typography
          style={{ fontSize: "12px", color: "grey" }}
          sx={{ mt: 5, mb: 5 }}
        >
          QE : {user_name}
        </Typography>
      </Grid>
      <Grid item xs={4} align="right" sx={{ ml: 1 }}>
        <Typography
          style={{ fontSize: "12px", color: "grey" }}
          sx={{ mt: 5, mb: 5 }}
        >
          Installer company
        </Typography>
      </Grid>
    </Grid>
  );
}

function ClickableBoxes({navigate}){
  const { data: job_card_data_pending, isSuccess: isGetJobCardPendingStateSuccess } =
  useGetPendingJobCardsQuery();
  const { data: job_card_data_history, isSuccess: isGetJobCardHistoryStateSuccess } =
    useGetQEJobCardHistoryQuery();
  if(isGetJobCardPendingStateSuccess &&
    isGetJobCardHistoryStateSuccess){
    const pending = (job_card_data_pending.job_cards.length)
    const pending_value= pending? pending : 0;

    const history = (job_card_data_history.job_cards.length)
    const history_value= history? history : 0;
  return(
  <Grid
  container
  alignItems="center"
  justifyContent="center"
  sx={{ mt: 1 }}
>
  <Grid item xs={11.5}>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "5px",
        p: 2,
        mb: 2,
        cursor: "pointer",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
      onClick={() => navigate("/qe/pending-installs")}
    >
      <Box display="flex" alignItems="center">
        <NotificationsActiveIcon sx={{ color: "#FDB940", mr: 2 }} />
        <Typography sx={{ color: "black" }}>Pending Installs</Typography>
      </Box>
      <Chip
        label={pending_value}
        sx={{ backgroundColor: "#FDB940", color: "#fff" }}
      />
    </Box>
  </Grid>

  <Grid item xs={11.5}>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "5px",
        p: 2,
        mb: 2,
        cursor: "pointer",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
      onClick={() => navigate("/qe/history")}
    >
      <Box display="flex" alignItems="center">
        <HistoryIcon sx={{ color: "#FDB940", mr: 2 }} />
        <Typography sx={{ color: "black" }}>History</Typography>
      </Box>
      <Chip
        label={history_value}
        sx={{ backgroundColor: "#FFF1D7", color: "black" }}
      />
    </Box>
  </Grid>


</Grid>
  )
}
else return null
}

function NewInstallButton({navigate}){
  return(
    <Grid
    container
    alignItems="center"
    justifyContent="center"
  >
    <Grid item xs={10} align="center">
    <Button
      style={{
        backgroundColor: "#FFF",
        color: "#000",
        padding: "10px 20px",
      }}
      endIcon={<ChevronRightIcon />}
      onClick={() => navigate(`/installer/`)}
    >
      New Install
    </Button>
  </Grid>
  </Grid>
  )
}