import * as React from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { setCurrentStep } from "../Stepper/stepperSlice";
import { useDispatch } from "react-redux";
import NavbarStepper from "../Stepper/NavBarStepper";
import { useParams } from "react-router-dom";
import { useInstallReplacementSmartNodeMutation,useGetIMSSmartnodeQuery, useAddChangeLogMutation } from "../../api/apiSlice";
import { useEffect } from "react";
import { showSuccess, showError } from "../../../Global/notifications/notificationsSlice";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useEditJobCardMutation } from "../../api/apiSlice";
import Typography from "@mui/material/Typography";

export default function QRCodeScanReplace() {
  const [
    editJobCard, 
    {
      isSuccess: iseditJobCardSuccess,
      isError: iseditJobCardError,
      error: editJobCardError
    }
  ] = useEditJobCardMutation();


  const [
    installReplacementSmartnode,
    {
      isSuccess: installReplacementSmartnodeSuccess,
      isError: isinstallReplacementSmartnodeError,
      error: installReplacementSmartnodeError,
    },
  ] = useInstallReplacementSmartNodeMutation();

  const [
    addChangeLog,
    {
      isSuccess: addChangeLogSuccess,
      isError: isaddChangeLogError,
      error: addChangeLogError,
    },
  ] = useAddChangeLogMutation();


  const [qrCodeData, setQRCodeData] = React.useState(null);
  const [isNodeExists, setIsNodeExists] = React.useState(false);

  let { job_card_reference_id } = useParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();


 dispatch(setCurrentStep(5));

 const { data: smartnode, isSuccess: isGetSmartnodeSuccess, isError: isGetSmartnodeError, error: getSmartNodeError } = useGetIMSSmartnodeQuery(
    qrCodeData ? { serial_number: qrCodeData.serial_number, imsi_number: qrCodeData.imsi_number } : skipToken
  );
  useEffect(() => {
    if ( installReplacementSmartnodeSuccess && qrCodeData ) {
      const { serial_number } = qrCodeData;
      const body = {
        serial_number:serial_number,
        change:"Installed as a replacement device, QE to approve",
      };
      addChangeLog({ body: body });
    }
  }, [installReplacementSmartnodeSuccess, job_card_reference_id, qrCodeData]);


  useEffect(() => {
    if ( addChangeLogSuccess  ) {
      
      const { serial_number, imsi_number } = qrCodeData;
      const body = {
        state: "DET",
        serial_number: serial_number,
        reference_id: job_card_reference_id,
        imsi_number: imsi_number,
       
      };
      editJobCard({ body: body });
      setIsNodeExists(true)
    } 
    else {
      setIsNodeExists(false)
    }
   
  }, [addChangeLogSuccess]);

  useEffect(() => {
    if (isGetSmartnodeSuccess && qrCodeData && isNodeExists==false) {
      setIsNodeExists(true)
      const { serial_number, imsi_number } = qrCodeData;
      const body = {
        
        serial_number: serial_number,
        reference_id: job_card_reference_id,
        imsi_number: imsi_number,
       
      };
      installReplacementSmartnode({ body: body });
    }
   
  }, [qrCodeData, isGetSmartnodeSuccess]);

  useEffect(() => {
    if (iseditJobCardSuccess &&  qrCodeData) {
      setIsNodeExists(true)
      navigate(`/installer/${job_card_reference_id}/${qrCodeData.serial_number}/${qrCodeData.imsi_number}/details`);
    }
   
  }, [iseditJobCardSuccess]);


  useEffect(() => {
    if (iseditJobCardError) {
      dispatch(showError(editJobCardError.data.error_message));
    }
   
  }, [iseditJobCardError, editJobCardError, dispatch, isNodeExists]);

  return (
    <>
      <NavbarStepper />
      <Typography sx={{color:"grey", fontSize: "16px"}}>Scan QR Code of the device being installed</Typography>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          align: "center",
          justifyContent: "center",
          marginTop: "-60px",
        }}
      >
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result, error) => {
            if (result) {
              const qr_code_data = extractDataFromUrl(result.text);
              setQRCodeData(qr_code_data);
            }
            if (error) {
              console.info(error);
            }
          }}
        />
      </div>
    </>
  );
}

function extractDataFromUrl(url) {
  const urlParts = url.split("/");
  if (urlParts.length < 7) {
    throw new Error(`Invalid URL format: Current Part length${urlParts.length}`);
  }
  return {
    serial_number: urlParts[5],
    imsi_number: urlParts[6],
  };
}
