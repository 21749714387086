import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "./apiHelper";
import { concatPathWithParams } from "./apiHelper";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SECRET_API}`,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: [
    "clients",
    "meter_groups",
    "meters",
    "meter",
    "meter_info",
    "meter_raw_data",
    "schedules",
    "graph",
    "filtered_graph",
    "lsps_state",
    "dsm_meters",
    "job_card_state",
    "smart_node",
    "settings",
    "schedulels",
    "scheduleps",
    "addSchedulePS",
    "hwi_graph",
    "hwi_table",
    "energy_graph",
    "energy_values",
    "residents_notifications",
    "check_ims_smartnode",
    "total_installed_capacity", "tjommie_data",
    "field_area_list",
    "installer_dashboard",
    "target",
    "stock_on_hand",
    "public_holiday",
    "tou",
    "tariff", 
    "pending",
    "history",
    "redo",
    "uninstall",
    "download_coc", "report"
  ],

  endpoints: (builder) => ({

    getIMSSmartnode: builder.query({
      query: ({ serial_number, imsi_number, url }) => {
        const queryParams = url ? `?url=${encodeURIComponent(url)}` : '';
        return {
          url: `${process.env.REACT_APP_IMS_API}/stock-item/${serial_number}/${imsi_number}/${queryParams}`,
        };
      },
      providesTags: ["check_ims_smartnode"],
    }),
    getFieldAreaList: builder.query({
      query: () => `${process.env.REACT_APP_IMS_API}/field-area/`,
      providesTags: ["field_area_list"],
    }),
    getDashboardInstaller: builder.query({
      query: (formattedDate) => `/installer/ims/dashboard/?formattedDate=${formattedDate}`,
      providesTags: ["installer_dashboard"],
    }),
    getTargetInstalls: builder.query({
      query: () => `${process.env.REACT_APP_IMS_API}/target-installs/`,
      providesTags: ["target"],
    }),
    getStockOnHand: builder.query({
      query: () => `${process.env.REACT_APP_IMS_API}/stock-on-hand/`,
      providesTags: ["stock_on_hand"],
    }),
    editIMSSmartnode: builder.mutation({
      query: (request) => ({
        url: `${process.env.REACT_APP_IMS_API}/stock-item/${request.body.serial_number}/${request.body.imsi_number}/`,
        method: "PATCH",
        body: request.body,
      }),
      invalidatesTags: ["check_ims_smartnode"],
    }),

    addChangeLog: builder.mutation({
      query: (request) => ({
        url: `${process.env.REACT_APP_IMS_API}/changelog/add/`,
        method: "POST",
        body: request.body,
      }),
      invalidatesTags: ["check_ims_smartnode"],
    }),
    getClients: builder.query({
      query: () => "/clients/",
      providesTags: ["clients"],
    }),

    getClientMeterGroups: builder.query({
      query: ({ client_id }) => `/client/${client_id}/meter-groups/`,
      providesTags: ["meter_groups"],
    }),

    getMeterGroupMeters: builder.query({
      query: ({ client_id, group_id }) =>
        `/client/${client_id}/group/${group_id}/meters/`,
      providesTags: ["meters"],
    }),

    getMeterGroupSchedules: builder.query({
      query: ({ client_id, group_id }) =>
        `/client/${client_id}/group/${group_id}/schedules/`,
      providesTags: ["schedules"],
    }),

    editSchedule: builder.mutation({
      query: (request) => ({
        url: `/dsm/schedule/${request.schedule_id}/`,
        method: "PATCH",
        body: request.body,
      }),
      invalidatesTags: ["schedules"],
    }),

    addScheduleToScheduleGroup: builder.mutation({
      query: (request) => ({
        url: `/dsm/schedule/add/group/${request.schedule_group_id}/`,
        method: "POST",
        body: request.body,
      }),
      invalidatesTags: ["schedules"],
    }),

    deleteSchedule: builder.mutation({
      query: (request) => ({
        url: `/dsm/schedule/${request.schedule_id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["schedules"],
    }),

    getMeter: builder.query({
      query: ({userTimezone, client_id, group_id, meter_id }) =>
        `/client/${client_id}/group/${group_id}/meter/${meter_id}/?usertimezone=${userTimezone}`,
      providesTags: ["meter"],
    }),

    getGraph: builder.query({
      query: ({userTimezone, client_id, group_id, meter_id, register }) =>
        `/client/${client_id}/group/${group_id}/meter/${meter_id}/${register}/?usertimezone=${userTimezone}`,
      providesTags: ["graph"],
    }),

    getFilteredGraph: builder.mutation({
      query: (request) =>
        concatPathWithParams(
          `/client/${request.queryParams.client_id}/group/${request.queryParams.group_id}/meter/${request.queryParams.meter_id}/${request.queryParams.register}/`,
          request.queryParams
        ),
      method: "GET",
      providesTags: ["filtered_graph"],
    }),

    //INSTALLER APP
    downloadInstallationImages: builder.mutation({
      query: (request) => ({
        url: `/installer/download/${request.job_card_id}/images/`,
        body: request.body,
        method: "POST",
        responseHandler: async (response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/zip")) {
            return response.blob();
          }
          return response.json();
        },
      }),
      invalidatesTags: ["download_images"],
    }),

    downloadDigitalCOC: builder.mutation({
      query: (request) => ({
        url: `/installer/download/${request.job_card_id}/digital_coc/`,
        body: request.body,
        method: "POST",
        responseHandler: async (response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/zip")) {
            return response.blob();
          }
          return response.json();
        },
      }),
      invalidatesTags: ["download_coc"],
    }),

    getJobCardState: builder.query({
      query: () => "/installer/job_card/",
      providesTags: ["job_card_state"],
    }),

    addjobCard: builder.mutation({
      query: (request) => ({
        url: `/installer/job_card/`,
        method: "POST",
        body: request.body,
      }),
      invalidatesTags: ["job_card_state"],
    }),

    editJobCard: builder.mutation({
      query: (request) => ({
        url: `/installer/job_card/`,
        method: "PATCH",
        body: request.body,
      }),
      invalidatesTags: ["job_card_state","pending","history","uninstall"],
    }),

    deleteJobCard: builder.mutation({
      query: (request) => ({
        url: `/installer/job_card/`,
        method: "DELETE",
        body: request.body,
      }),
      invalidatesTags: ["job_card_state"],
    }),

    getSmartNodeStatus: builder.query({
      query: (queryParams) => concatPathWithParams("/installer/smart_node/", queryParams),
      providesTags: ["smart_node"],
    }),

    editSmartNode: builder.mutation({
      query: (request) => ({
        url: `/installer/smart_node/`,
        method: "PATCH",
        body: request.body,
      }),
      invalidatesTags: ["job_card_state"],
    }),

    getDeviceSwitchingGraphData: builder.query({
      query: (queryParams) => concatPathWithParams("/municipality/device-switching-data/", queryParams),
      providesTags: ["device-switching-data"],
    }),

    getTjommieGraphData: builder.query({
      query: (queryParams) => concatPathWithParams("/municipality/tjommie-data/", queryParams),
      providesTags: ["tjommie-data"],
    }),

    getADMDGraphData: builder.query({
      query: (queryParams) => concatPathWithParams("/municipality/admd-graph/", queryParams),
      providesTags: ["admd-graph"],
    }),


    getTestingPipeline: builder.query({
      query: (queryParams) => concatPathWithParams("/installer/testing_pipeline/", queryParams),
      providesTags: ["testing_pipeline"],
    }),
    getTotalInstalledCapacity: builder.query({
      query: () =>
        `/total-installed-capacity/`,
      providesTags: ["total_installed_capacity"],
    }),
    getPendingJobCards: builder.query({
      query: () => "/installer/qe/pending/",
      providesTags: ["pending"],
    }),
    getQEJobCardHistory: builder.query({
      query: () => "/installer/qe/history/",
      providesTags: ["history"],
    }),

    getDeclinedJobCards: builder.query({
      query: () => "/installer/job_cards/declined/",
      providesTags: ["redo"],
    }),

    editRedoInstall: builder.mutation({
      query: (request) => ({
        url: `/installer/job_card/redo/${request.body.job_card_id}/`,
        method: "PATCH",
        body: request.body,
      }),
      invalidatesTags: ["redo","job_card_state"],
    }),

    getUninstallJobCard: builder.query({
      query: ({ serial_number, imsi_number}) => (`/installer/job_card/uninstall/${imsi_number}/${serial_number}/`),
      providesTags: ["uninstall"],
    }),

    uninstallSmartNode: builder.mutation({
      query: (request) => ({
        url: `/installer/smart_node/uninstall/${request.body.archived_job_card_number}/${request.body.serial_number}/`,
        method: "POST",
        body: request.body,
      }),
      invalidatesTags: ["job_card_state"],
    }),

    uninstallReplacementSmartNode: builder.mutation({
      query: (request) => ({
        url: `/installer/smart_node/replacement/uninstall/${request.body.archived_job_card_number}/${request.body.serial_number}/`,
        method: "POST",
        body: request.body,
      }),
      invalidatesTags: ["job_card_state"],
    }),

    installReplacementSmartNode: builder.mutation({
      query: (request) => ({
        url: `/installer/smart_node/replacement/install/${request.body.reference_id}/${request.body.serial_number}/${request.body.imsi_number}/`,
        method: "POST",
        body: request.body,
      }),
      invalidatesTags: ["job_card_state"],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetClientMeterGroupsQuery,
  useGetMeterGroupSchedulesQuery,
  useGetMeterGroupMetersQuery,
  useGetMeterQuery,
  useGetGraphQuery,
  useGetFilteredGraphMutation,
  useEditScheduleMutation,
  useDeleteScheduleMutation,
  useAddScheduleToScheduleGroupMutation,
  useGetTotalInstalledCapacityQuery,
  useAddChangeLogMutation,
  //INSTALLER APP
  useDownloadInstallationImagesMutation,
  useDownloadDigitalCOCMutation,
  useGetJobCardStateQuery,
  useAddjobCardMutation,
  useEditJobCardMutation,
  useDeleteJobCardMutation,
  useGetSmartNodeStatusQuery,
  useEditSmartNodeMutation,
  useGetTestingPipelineQuery,
  useGetPendingJobCardsQuery,
  useGetQEJobCardHistoryQuery,
  useGetDeclinedJobCardsQuery,
  useEditRedoInstallMutation,
  useGetUninstallJobCardQuery,
  useUninstallSmartNodeMutation,
  useUninstallReplacementSmartNodeMutation,
  useInstallReplacementSmartNodeMutation,
  //MUNICIPALITY APP
  useGetDeviceSwitchingGraphDataQuery,
  useGetADMDGraphDataQuery,
  useGetTjommieGraphDataQuery,
  // IMS
  useGetIMSSmartnodeQuery,
  useEditIMSSmartnodeMutation,
  useGetFieldAreaListQuery,
  useGetDashboardInstallerQuery,
  useGetTargetInstallsQuery,
  useGetStockOnHandQuery,
} = apiSlice;
