import * as React from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import theme from "../../../Global/theme";
import { setCurrentStep } from "../Stepper/stepperSlice";
import { useDispatch } from "react-redux";
import NavbarStepper from "../Stepper/NavBarStepper";
import { TextField, InputAdornment } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useEditJobCardMutation } from "../../api/apiSlice";
import { showError } from "../../../Global/notifications/notificationsSlice";

export default function Uninstall() {
  let { serial_number } = useParams();
  let { imsi_number } = useParams();
  let { job_card_reference_id } = useParams();
  let { uninstall } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [notes, setNotes] = React.useState("");
  const [
    editJobCard,
    { data: job_card, isSuccess: isEditJobCardSuccess, isError: isEditJobCardError },
  ] = useEditJobCardMutation();


  
  const handleChangeNotes = (event) => {
    setNotes(event.target.value);
  };

  const handlNext = () => {
    const body = {
      state: uninstall=="UN"? "AFT" : "QRCRE" ,
      serial_number: serial_number,
      imsi_number: imsi_number,
      reference_id: job_card_reference_id,
      installer_notes: notes,
    };
    editJobCard({ body: body });
  };



  React.useEffect(() => {
    if (isEditJobCardError) {
       dispatch(showError("Error"));
    }
  }, [isEditJobCardError]);


  return (
    <>
      <NavbarStepper />
      <Grid container alignItems="center" justifyContent="center">

        <Grid item xs={12} align="center">
          <Typography variant="h5" color="grey" sx={{ mb: 2 }}>
           Uninstall
          </Typography>
        </Grid>

  
        <Grid item xs={10} align="center" sx={{ mt: 2, mb: 2 }}>
          <TextField
            label="Installer Notes"
            value={notes}
            onChange={handleChangeNotes}
            multiline
            rows={6}
            variant="outlined"
            fullWidth
            placeholder="Capture additional installation notes here..."
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4} align="center">
          <Button
            style={theme.yellowButtonStyle.root}
            onClick={() => handlNext()}
          >
            Next
          </Button>
        </Grid>
        <Grid item xs={4} />
      </Grid>
    </>
  );
}
