import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";
import { concatPathWithParams } from "../api/apiHelper";

const operatorDashboardAdapter = createEntityAdapter();

const initialState = operatorDashboardAdapter.getInitialState();

export const operatorDashboardAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLSPSState: builder.query({
      query: () => "/dsm/lspsstate/",
      providesTags: ["lsps_state"],
    }),

    getDSMMeters: builder.query({
      query: (queryParams) => concatPathWithParams("/dsm/meters/", queryParams),
      providesTags: ["dsm_meters"],
    }),

    getMicroServiceMonitoring: builder.query({
      query: () => "/dsm/microservice-monitoring/",
      providesTags: ["graph"],
    }),

    getMicroServiceMonitoringForcast: builder.query({
      query: () => "/dsm/microservice-monitoring-forcast/",
      providesTags: ["graph"],
    }),

    getTargetDataNow: builder.query({
      query: () => "/dsm/target-data-now/",
      providesTags: ["graph"],
    }),

    getDashboardStatus: builder.query({
      query: (timezone) =>
        `/dsm/operator-dashboard-status/?timezone=${timezone}`,
      providesTags: ["lsps_state"],
    }),

    getMicroServiceMonitoringGraphData: builder.query({
      query: (request) =>
          concatPathWithParams(
              `dsm/microservice-monitoring/graph/`,
              request.queryParams
          ),
      providesTags: ["graph"],
  }),

    updateLSPSState: builder.mutation({
      query: (request) => ({
        url: `/dsm/lspsstate/`,
        body: request.body,
        method: "PATCH",
      }),
      invalidatesTags: ["lsps_state"],
    }),

    resetGeysers: builder.mutation({
      query: (request) => ({
        url: `/dsm/geyser-emergency-on/`,
        body: request.body,
        method: "POST",
      }),
      invalidatesTags: ["dsm_meters"],
    }),

    getHWIGraph: builder.query({
      query: (request) =>
        `/dsm/operator-dashboard/hot-water-index/${request.meter_id}/?timezone=${request.timezone}`,
      providesTags: ["hwi_graph"],
    }),
    getHWITable: builder.query({
      query: ({ meter_id, userTimezone }) =>
        `/dsm/operator-dashboard/hot-water-index/meter/${meter_id}/?userTimeZone=${userTimezone}`,
      providesTags: ["hwi_table"],
    }),

    getEnergyGraph: builder.query({
      query: (request) =>
        `/dsm/municipality-dashboard/?start_date=${request.start_date}&end_date=${request.end_date}&user-timezone=${request.user_timezone}`,
      providesTags: ["energy_graph"],
    }),

    getEnergyValues: builder.query({
      query: (request) =>
        `/dsm/municipality-dashboard/values/?start_date=${request.start_date}&end_date=${request.end_date}&user-timezone=${request.user_timezone}`,
      providesTags: ["energy_values"],
    }),
  }),
});

export const {
  useGetLSPSStateQuery,
  useGetDSMMetersQuery,
  useGetDashboardStatusQuery,
  useGetMicroServiceMonitoringQuery,
  useGetMicroServiceMonitoringForcastQuery,
  useGetTargetDataNowQuery,
  useGetMicroServiceMonitoringGraphDataQuery,
  useUpdateLSPSStateMutation,
  useGetHWIGraphQuery,
  useGetHWITableQuery,
  useGetEnergyGraphQuery,
  useGetEnergyValuesQuery,
  useResetGeysersMutation,
} = operatorDashboardAPISlice;
