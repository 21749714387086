import * as React from "react";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { setlogo, setnavHeading } from "./Nav/navSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";

import { useGetQEJobCardHistoryQuery } from "../api/apiSlice";
export default function History() {
  const dispatch = useDispatch();
  dispatch(setnavHeading("History"));
  dispatch(setlogo(false));

  const { data: job_card_data, isSuccess: isGetJobCardHistoryStateSuccess } =
    useGetQEJobCardHistoryQuery();

    if(isGetJobCardHistoryStateSuccess){
  return (
    <>
      <Helmet>
        <style>{"body { background: #FFF1D7; }"}</style>
      </Helmet>

      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        sx={{ mt: 10 }}
      >
        <HistoryList data={job_card_data.job_cards} />
      </Grid>
    </>
  );
}
else return null;
}

function HistoryList({ data }) {
  const navigate = useNavigate();
  return data.map((item) => (
    <>
      <Grid item xs={0.25} />
      <Grid
        item
        xs={11.5}
        key={item.id}
        onClick={() => {
          navigate(`/qe/${'history'}/install/details/${item.id}/`);
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            p: 2,
            mb: 2,
            cursor: "pointer",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }}>
            <Typography sx={{ color: "grey", fontSize: "12px" }}>
              Address
            </Typography>
            <Typography sx={{ color: "black" }}>{item.address}</Typography>
          </Box>
          <ApprovedDeclinedIcon status={item.qe_job_state} />
        </Box>
      </Grid>
      <Grid item xs={0.25} />
    </>
  ));
}

function ApprovedDeclinedIcon({ status }) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {status === "Approved" ? (
        <>
          <Typography sx={{ color: "grey", fontSize: "12px" }}>
            Approved
          </Typography>
          <CheckCircleIcon sx={{ color: "green", fontSize: 24 }} />
        </>
      ) : (
        <>
          <Typography sx={{ color: "grey", fontSize: "12px" }}>
            Declined
          </Typography>
          <CancelIcon sx={{ color: "red", fontSize: 24 }} />
        </>
      )}
    </Box>
  );
}
