import Grid from "@mui/material/Grid";
import OperatorDashboardToggle from "./OperatorDashboardToggle";
import OperatorDataGrid from "./OperatorDataGrid";
import OperatorDashboardDemand from "./OperatorDashboardDemand";
import OpperatorDashboardGraph from "./OpperatorDashboardGraph";
import { useState } from "react";
import dayjs from "dayjs";

const styles = {
  container: {
    margin: "0 auto",
    padding: "0 16px",
    maxWidth: "1350px",
    marginTop: 70,
  },
};

export default function OperatorDashboardPage() {
  const [startDate] = useState(dayjs().subtract(1, "day"));
  const [endDate] = useState(dayjs());
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const request = {
    queryParams: {
      startDate: null,
      endDate: null,
      userTimezone: userTimezone,
    },
  };

  return (
    <>
      <Grid style={styles.container}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="left"
          sx={{ mt: 10 }}
        >
          <OperatorDashboardToggle />
        </Grid>
        <Grid
          sx={{ marginTop: "20px" }}
          container
          spacing={0.5}
          alignItems="center"
          justifyContent="left"
        >
          <OperatorDashboardDemand />
        </Grid>
        <Grid
          sx={{ marginTop: "20px" }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <OpperatorDashboardGraph request={request} pollingInterval={60000} />
        </Grid>
        <Grid
          sx={{ marginTop: "20px" }}
          container
          spacing={2}
          alignItems="center"
          justifyContent="left"
        >
          <OperatorDataGrid />
        </Grid>
      </Grid>
    </>
  );
}
