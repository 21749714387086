import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import camera_place_holder from "../../Global/images/camera_place_holder.jpg";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { useParams } from "react-router-dom";
import {
  useEditJobCardMutation,
  useGetJobCardStateQuery,
} from "../api/apiSlice";
import CircularProgress from "@mui/material/CircularProgress";
import {
  setCurrentStep,
  getCurrentStep,
  setsteps,
} from "./Stepper/stepperSlice";
import { useSelector, useDispatch } from "react-redux";
import NavbarStepper from "./Stepper/NavBarStepper";
import theme from "../../Global/theme";

export function BeforeInstallPage() {
  let { job_card_reference_id } = useParams();
  let { serial_number } = useParams();
  let { imsi_number } = useParams();
  const { data: job_card_data, isSuccess: isGetJobCardStateSuccess } =
    useGetJobCardStateQuery();

  const dispatch = useDispatch();

  const first_photo_name = "before_photo_on";
  const second_photo_name = "before_photo_off";
  const first_photo_heading_text =
    "Take a photo of the DB board BEFORE installation with COVER ON";
  const second_photo_heading_text =
    "Take a photo of the DB board BEFORE installation with COVER OFF";

  const [successful_navigation_link, setsuccessful_navigation_link] =
    React.useState("");
  const [update_to_state, setupdate_to_state] = React.useState("");

  useEffect(() => {
    if (isGetJobCardStateSuccess) {
      if (
        job_card_data.install_state === "UN" ||
        job_card_data.install_state === "RE"
      ) {
        dispatch(setCurrentStep(2));
        setsuccessful_navigation_link(
          `/installer/${job_card_reference_id}/${serial_number}/${imsi_number}/${job_card_data.install_state}/`
        );
        setupdate_to_state("UNI");
      } else {
        setsuccessful_navigation_link`/installer/${job_card_reference_id}/${serial_number}/${imsi_number}/connection_test_interlude/`;
        setupdate_to_state("AMP");
        dispatch(setCurrentStep(3));
      }
    }
  }, [isGetJobCardStateSuccess]);

  return (
    <>
      <PhotoCaptureProcess
        first_photo_name={first_photo_name}
        second_photo_name={second_photo_name}
        first_photo_heading_text={first_photo_heading_text}
        second_photo_heading_text={second_photo_heading_text}
        successful_navigation_link={successful_navigation_link}
        update_to_state={update_to_state}
        job_state={job_card_data.install_state}
      />
    </>
  );
}

export function AfterInstallPage() {
  let { job_card_reference_id } = useParams();
  let { serial_number } = useParams();
  let { imsi_number } = useParams();

  const { data: job_card_data, isSuccess: isGetJobCardStateSuccess } =
    useGetJobCardStateQuery();

  const first_photo_name = "after_photo_off";
  const second_photo_name = "after_photo_on";
  const first_photo_heading_text =
    "Take a photo of the DB board AFTER installation with COVER OFF";
  const second_photo_heading_text =
    "Take a photo of the DB board AFTER installation with COVER ON";
  const successful_navigation_link = `/installer/${job_card_reference_id}/${serial_number}/${imsi_number}/after_install_work_area/`;
  const update_to_state = "AFTWA";

  return (
    <>
      <PhotoCaptureProcess
        first_photo_name={first_photo_name}
        second_photo_name={second_photo_name}
        first_photo_heading_text={first_photo_heading_text}
        second_photo_heading_text={second_photo_heading_text}
        successful_navigation_link={successful_navigation_link}
        update_to_state={update_to_state}
        job_state={job_card_data.install_state}
      />
    </>
  );
}

function PhotoCaptureProcess({
  first_photo_name,
  second_photo_name,
  first_photo_heading_text,
  second_photo_heading_text,
  successful_navigation_link,
  update_to_state,
  job_state,
}) {
  const dispatch = useDispatch();
  let step = useSelector(getCurrentStep);
  let { job_card_reference_id } = useParams();
  let navigate = useNavigate();
  const [
    editJobCard,
    { isSuccess: isEditJobCardSuccess, isLoading: isEditJobCardLoading },
  ] = useEditJobCardMutation();

  const [photo_number, setPhotoNumber] = useState(0);
  const [first_photo, setFirstPhoto] = useState(null);
  const [second_photo, setSecondPhoto] = useState(null);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleSubmitPhotos = () => {
    const body = new FormData();
    body.append(first_photo_name, first_photo);
    body.append(second_photo_name, second_photo);
    body.append("reference_id", job_card_reference_id);
    body.append("state", update_to_state);
    editJobCard({ body: body });
  };

  useEffect(() => {
    if (isEditJobCardSuccess) {
      navigate(successful_navigation_link);
    }
  }, [isEditJobCardSuccess]);

  useEffect(() => {
    if (photo_number === 1 && step === 3) {
      if (job_state != "UN") {
        dispatch(setCurrentStep(4));
        step = 4;
      } else if (
        photo_number === 1 &&
        (job_state === "UN" || job_state == "RE")
      ) {
        dispatch(setCurrentStep(3));
        step = 3;
      }
    } else if (photo_number === 1 && step === 2) {
      dispatch(setCurrentStep(3));
      step = 3;
    } else if (photo_number === 1 && step === 7) {
      dispatch(setCurrentStep(8));
      step = 8;
    } else if (photo_number === 1 && step === 9) {
      dispatch(setCurrentStep(10));
      step = 10;
    } else if (photo_number === 1 && step === 5) {
      dispatch(setCurrentStep(6));
      step = 6;
    }
  }, [photo_number]);

  if (isMobile) {
    if (photo_number === 0) {
      return (
        <>
          <NavbarStepper step={step} />

          <Grid container alignItems="center" justifyContent="center">
            <PageHeading heading_text={first_photo_heading_text} />

            <TakePhoto
              photo={first_photo}
              setPhoto={setFirstPhoto}
              setPhotoNumber={setPhotoNumber}
              photo_number={photo_number}
            />
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <NavbarStepper step={step} />

          <Grid container alignItems="center" justifyContent="center">
            <PageHeading heading_text={second_photo_heading_text} />

            <TakePhoto
              photo={second_photo}
              setPhoto={setSecondPhoto}
              setPhotoNumber={setPhotoNumber}
              photo_number={photo_number}
              handleSubmitPhotos={handleSubmitPhotos}
              submit_photos_loading={isEditJobCardLoading}
            />
          </Grid>
        </>
      );
    }
  } else {
    return <p>Sorry, this feature is only available on mobile devices.</p>;
  }
}

function PageHeading({ heading_text }) {
  return (
    <>
      <Grid item xs={1} />
      <Grid item xs={10} align="center">
        <Typography variant="h6" sx={{ mt: 1, mb: 5 }}>
          {heading_text}
        </Typography>
      </Grid>
      <Grid item xs={1} />
    </>
  );
}

function TakePhoto({
  photo,
  setPhoto,
  setPhotoNumber,
  photo_number,
  handleSubmitPhotos,
  submit_photos_loading,
}) {
  const dispatch = useDispatch();
  const [no_photo_error, setNoPhotoError] = useState(false);
  const [are_you_sure_flag, setAreYouSureFlag] = useState(false);
  const [frontend_photo_placeholder, setFrontendPhotoPlaceholder] =
    useState(camera_place_holder);

  const handleCapture = (event) => {
    const file = event.target.files[0];
    const photoURL = URL.createObjectURL(file);
    setPhoto(file);
    setFrontendPhotoPlaceholder(photoURL);
    setNoPhotoError(false);
  };

  const HandleNextButton = () => {
    if (photo_number == 0) {
      if (photo === null) {
        setNoPhotoError(true);
      } else {
        setAreYouSureFlag(true);
        setPhotoNumber(1);
        setFrontendPhotoPlaceholder(camera_place_holder);
      }
    } else {
      if (photo === null) {
        setNoPhotoError(true);
      } else {
        handleSubmitPhotos();
      }
    }
  };

  return (
    <>
      <input
        accept="image/*"
        id="icon-button-file"
        type="file"
        capture="environment"
        onChange={handleCapture}
        style={{ display: "none" }}
      />

      <Grid item xs={1} />
      <Grid item xs={10} align="center">
        {frontend_photo_placeholder && (
          <img
            src={frontend_photo_placeholder}
            alt="Captured"
            style={{ objectFit: "cover", width: "200px", height: "200px" }}
          />
        )}
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={1} />
      <Grid item xs={10} align="center">
        <label htmlFor="icon-button-file">
          <Button
            variant="outlined"
            style={theme.blackButtonStyle.root}
            component="span"
            sx={{ mt: 2 }}
          >
            {photo === null ? "Capture Photo" : "Re-take Picture"}
          </Button>
        </label>
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={1} />
      <Grid item xs={10} align="center">
        <Collapse in={no_photo_error}>
          <Alert severity="error" sx={{ mt: 2 }}>
            You have not taken any photos yet
          </Alert>
        </Collapse>
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={1} />
      <Grid item xs={10} align="center">
        <Collapse in={photo !== null}>
          <Alert severity="warning" sx={{ mt: 1 }}>
            Once you've clicked next, you cannot retake this photo!
          </Alert>
        </Collapse>
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={1} />
      <Grid item xs={10} align="right">
        {submit_photos_loading ? (
          <CircularProgress sx={{ mt: 2 }} />
        ) : (
          <Button
            variant="contained"
            style={theme.yellowButtonStyle.root}
            sx={{ mt: 4 }}
            onClick={HandleNextButton}
          >
            NEXT
          </Button>
        )}
      </Grid>
      <Grid item xs={1} />
    </>
  );
}
