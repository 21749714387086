import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import {
  useGetLSPSStateQuery,
  useUpdateLSPSStateMutation,
  useGetDashboardStatusQuery,
} from "./operatorDashboardAPISlice";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

export default function OperatorDashboardToggle() {
  const [selectedPeakShaving, setSelectedPeakShaving] = useState(false);
  const [selectedLoadShifting, setSelectedLoadShifting] = useState(false);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const {
    data: lspsState,
    isLoading: isLSPSStateLoading,
    isError: isLSPSStateError,
  } = useGetLSPSStateQuery();

  const {
    data: dashboardStatus,
    isLoading: isDashboardStatusLoading,
    isError: isDashboardStatusError,
  } = useGetDashboardStatusQuery(userTimezone, {
    pollingInterval: 60000,
  });

  const [
    updateLSPSState,
    {
      data: updatedLSPSState,
      isLoading: updateLSPSStateLoading,
      isError: updateLSPSStateError,
      error: LSPSStateUpdateError,
    },
  ] = useUpdateLSPSStateMutation();

  const handleChangePeakShaving = (event) => {
    const { checked } = event.target;
    setSelectedPeakShaving(checked);
    const request = {
      body: {
        switch_peak_shaving: checked,
        switch_load_shifting: selectedLoadShifting,
      },
    };
    updateLSPSState(request);
  };

  const handleChangeLoadShifting = (event) => {
    const { checked } = event.target;
    setSelectedLoadShifting(checked);
    const request = {
      body: {
        switch_peak_shaving: selectedPeakShaving,
        switch_load_shifting: checked,
      },
    };
    updateLSPSState(request);
  };

  React.useEffect(() => {
    if (lspsState) {
      setSelectedPeakShaving(lspsState.switch_peak_shaving);
      setSelectedLoadShifting(lspsState.switch_load_shifting);
    }
  }, [lspsState]);

  if (
    isLSPSStateLoading ||
    updateLSPSStateLoading ||
    isDashboardStatusLoading
  ) {
    return (
      <>
        <Grid
          style={{ marginLeft: "50px" }}
          item
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Grid>
        <Grid item alignItems="center" justifyContent="center"></Grid>
        <p>Loading current PS and LS state</p>
      </>
    );
  } else if (isLSPSStateError) {
    return (
      <Grid
        style={{ marginLeft: "50px" }}
        item
        alignItems="center"
        justifyContent="center"
      >
        Error retrieving current Load shifting / Peak shaving state
      </Grid>
    );
  } else {
    return (
      <>
        <DashboardFractionValues dashboardStatus={dashboardStatus} />
      </>
    );
  }
}

function DashboardFractionValues({ dashboardStatus }) {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-around"
      style={{ width: "100%" , marginTop: "20px", marginBottom: "10px"}}
    >
      <Grid item>
        <Typography>
          Connected - {dashboardStatus.devices_connected} /{" "}
          {dashboardStatus.total_geysers}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          ≥ Consistency Threshold - {dashboardStatus.consistent_devices} /{" "}
          {dashboardStatus.devices_connected}
        </Typography>
        </Grid>
      <Grid item>
        <Typography>
          Under Control - {dashboardStatus.total_meter_control} /{" "}
          {dashboardStatus.devices_connected}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Thermo On - {dashboardStatus.total_geysers_on} /{" "}
          {dashboardStatus.devices_connected}
        </Typography>
      </Grid>
    </Grid>
  );
}
