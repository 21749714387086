import * as React from "react";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import Stack from "@mui/material/Stack";
import NfcIcon from '@mui/icons-material/Nfc';
import {
  useGetSmartNodeStatusQuery,
  useEditJobCardMutation,
} from "../api/apiSlice";
import Button from "@mui/material/Button";
import { setCurrentStep } from "./Stepper/stepperSlice";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import NavbarStepper from "./Stepper/NavBarStepper";
import theme from "../../Global/theme";
import CircleIcon from "@mui/icons-material/Circle";

export default function ConnectionTestPage() {
  const dispatch = useDispatch();
 
  let { serial_number, imsi_number } = useParams();

  const QueryParams = {
    serial_number: serial_number,
  };
  const {
    data: smart_node_data,
    isSuccess: isGetSmartNodeSuccess,
    refetch: refetchSmartNodeData,
  } = useGetSmartNodeStatusQuery(QueryParams);

  if (isGetSmartNodeSuccess) {
    return (
      <>
        <NavbarStepper />

        <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={1} align="left" />
          <Grid item xs={10} align="center"  sx={{ mb: 2 }}>
            <Typography
              variant="h8"
              color="grey"
             
            >
              Test the device connection.
            </Typography>
          </Grid>
          <Grid item xs={1} align="left" />
          <Grid item xs={10} align="center">
            <Box bgcolor="grey.200" p={2} align="center" sx={{ borderRadius: 4 }}>
              <Grid container>
                <Grid item xs={12} align="center">
                  <NfcIcon/>
                  <Typography sx={{ mt: 2, mb:2, fontSize: 18 }}>Device Details</Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={10} align="left">
                  <ConnectionStatus connected={smart_node_data.connected} />
                  <Typography sx={{ mt: 1 }}>SN: {serial_number}</Typography>
                  <Typography sx={{ mt: 1 }}>IMSI : {imsi_number}</Typography>
                  <Typography sx={{ mt: 1 }}>
                    Address: {smart_node_data.address}
                  </Typography>
                </Grid>
                <Grid item xs={1} />

                <Grid item xs={1} />
                <Grid item xs={10} align="center">
                  <Button
                    variant="outlined"
                    style={theme.blackButtonStyle.root}
                    sx={{ mt: 2 }}
                    onClick={refetchSmartNodeData}
                    endIcon={<RefreshIcon />}
                  >
                    Test Connection
                  </Button>
                </Grid>
                <Grid item xs={1} />
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={10} align="right">
            <NextButton connected={smart_node_data.connected} />
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
}

function ConnectionStatus({ connected }) {
  if (connected) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography>Connected</Typography>
        <CircleIcon sx={{ color: "#119B05" }} />
      </Stack>
    );
  } else if (!connected) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography>Not Connected </Typography>
        <CircleIcon sx={{ color: "red" }} />
      </Stack>
    );
  } else {
    return <Typography>Unknown</Typography>;
  }
}

function NextButton({ connected }) {

  let { serial_number, imsi_number } = useParams();
  let { job_card_reference_id } = useParams();
  let navigate = useNavigate();

  const [editJobCard, { isSuccess: isEditJobCardSuccess }] =
    useEditJobCardMutation();

  const handleConnectionTestCompleted = () => {
    const body = {
      reference_id: job_card_reference_id,
      state: "AFT",
    };
    editJobCard({ body: body });
  };

  useEffect(() => {
    if (isEditJobCardSuccess) {
      navigate(`/installer/${job_card_reference_id}/${serial_number}/${imsi_number}/after_install/`);
    }
  }, [isEditJobCardSuccess]);

  if (connected) {
    return (
      <Button
        variant="contained"
        style={theme.yellowButtonStyle.root}
        sx={{ mt: 2 }}
        onClick={handleConnectionTestCompleted}
      >
        NEXT
      </Button>
    );
  } else {
    return (
      <Button variant="contained" color="primary" sx={{ mt: 2 }} disabled>
        NEXT
      </Button>
    );
  }
}
