import * as React from "react";
import { useGetJobCardStateQuery } from "../api/apiSlice";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import theme from "../../Global/theme";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { getUserName } from "../Authentication/authSlice";
import LogOutDialog from "./LogoutDialog";
import { WithAnyPermissionGroupTrue } from "../../Global/permissions/checkPermissions";
import Alert from "@mui/material/Alert";

import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";

import { useGetDeclinedJobCardsQuery } from "../api/apiSlice";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setsteps, getsteps, setCurrentStep } from "./Stepper/stepperSlice";

function ConfirmReplaceUninstallDialog({ open, handleClose, type }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let text = type == "UN" ? "UNINSTALL" : "REPLACE";

  const handleConfirm = () => {
    if (type == "RE") {
      dispatch(setsteps([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]));
    } else {
      dispatch(setsteps([0, 1, 2, 3, 4, 5, 6, 7]));
    }
    navigate(`/installer/uninstall/${type}/`);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText style={{ fontSize: "1.5rem", textAlign: "left" }}>
          Are you sure you want to
          <br />
          <strong>{text}</strong> the device?
        </DialogContentText>
        <Alert severity="warning" sx={{ mt: 1, mb: 1 }}>
          You will not be able to cancel an uninstall
        </Alert>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "center", paddingBottom: "16px" }}
      >
        <Button
          onClick={handleClose}
          style={{ color: "#1e88e5", fontSize: "1rem" }}
        >
          BACK
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          style={{
            backgroundColor: "#1e88e5",
            color: "#fff",
            fontSize: "1rem",
          }}
        >
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default function InstallerLandingPage() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const steps = useSelector(getsteps);
  const user_name = useSelector(getUserName);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("");
  const { data: job_card_data, isSuccess: isGetJobCardStateSuccess } =
    useGetJobCardStateQuery();

  const handleUninstall = () => {
    setOpen(true);
    setType("UN");
    dispatch(setCurrentStep(0));
    dispatch(setsteps([0, 1, 2, 3, 4, 5, 6, 7]));
  };

  const handleReplacement = () => {
    setOpen(true);
    setType("RE");
    dispatch(setCurrentStep(0));
    dispatch(setsteps([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]));
  };

  const handleNew = () => {
    dispatch(setsteps([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]));
    dispatch(setCurrentStep(0));
    navigate(`/installer/details/`)
  };
 
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (isGetJobCardStateSuccess) {
      if (job_card_data.install_state == "NEW") {
        dispatch(setsteps([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]));
        if (job_card_data.state === "QRC") {
          dispatch(setCurrentStep(1));
          navigate(`/installer/${job_card_data.reference_id}/qrcode/`);
        } else if (job_card_data.state === "BEF") {
          dispatch(setCurrentStep(3));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/before_install/`
          );
        } else if (job_card_data.state === "BEFWA") {
          dispatch(setCurrentStep(2));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/work_area_before_install/`
          );
        } else if (job_card_data.state === "AMP") {
          dispatch(setCurrentStep(5));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/connection_test_interlude/`
          );
        } else if (job_card_data.state === "TES") {
          dispatch(setCurrentStep(6));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/connection_test/`
          );
        } else if (job_card_data.state === "AFT") {
          dispatch(setCurrentStep(7));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/after_install/`
          );
        } else if (job_card_data.state === "AFTWA") {
          dispatch(setCurrentStep(9));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/after_install_work_area/`
          );
        } else if (job_card_data.state === "REF") {
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/job_completed/`
          );
        }
      } else if (job_card_data.install_state == "UN") {
        dispatch(setsteps([0, 1, 2, 3, 4, 5, 6, 7]));
        if (job_card_data.state === "BEF") {
          dispatch(setCurrentStep(2));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.archived_serial_number}/${job_card_data.archived_imsi_number}/before_install/`
          );
        } else if (job_card_data.state === "BEFWA") {
          dispatch(setCurrentStep(1));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.archived_serial_number}/${job_card_data.archived_imsi_number}/work_area_before_install/`
          );
        } else if (job_card_data.state === "UNI") {
          dispatch(setCurrentStep(4));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.archived_serial_number}/${job_card_data.archived_imsi_number}/${job_card_data.install_state}/`
          );
        } else if (job_card_data.state === "AFT") {
          dispatch(setCurrentStep(5));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.archived_serial_number}/${job_card_data.archived_imsi_number}/after_install/`
          );
        } else if (job_card_data.state === "AFTWA") {
          dispatch(setCurrentStep(7));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.archived_serial_number}/${job_card_data.archived_imsi_number}/after_install_work_area/`
          );
        } else if (job_card_data.state === "REF") {
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.archived_serial_number}/${job_card_data.archived_imsi_number}/job_completed/`
          );
        }
      } else if (job_card_data.install_state == "RE") {
        dispatch(setsteps([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]));
        if (job_card_data.state === "QRCRE") {
          dispatch(setCurrentStep(5));
          navigate(
            `/installer/${job_card_data.reference_id}/qrcode/replacement/`
          );
        } else if (job_card_data.state === "BEF") {
          dispatch(setCurrentStep(2));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.archived_serial_number}/${job_card_data.archived_imsi_number}/before_install/`
          );
        } else if (job_card_data.state === "BEFWA") {
          dispatch(setCurrentStep(1));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.archived_serial_number}/${job_card_data.archived_imsi_number}/work_area_before_install/`
          );
        } else if (job_card_data.state === "UNI") {
          dispatch(setCurrentStep(4));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.archived_serial_number}/${job_card_data.archived_imsi_number}/${job_card_data.install_state}/`
          );
        } else if (job_card_data.state === "DET") {
          dispatch(setCurrentStep(6));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/details/`
          );
        } else if (job_card_data.state === "AMP") {
          dispatch(setCurrentStep(7));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/connection_test_interlude/`
          );
        } else if (job_card_data.state === "TES") {
          dispatch(setCurrentStep(8));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/connection_test/`
          );
        } else if (job_card_data.state === "AFT") {
          dispatch(setCurrentStep(9));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/after_install/`
          );
        } else if (job_card_data.state === "AFTWA") {
          dispatch(setCurrentStep(11));
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/after_install_work_area/`
          );
        } else if (job_card_data.state === "REF") {
          navigate(
            `/installer/${job_card_data.reference_id}/${job_card_data.serial_number}/${job_card_data.imsi_number}/job_completed/`
          );
        }
      }
    }
  }, [isGetJobCardStateSuccess, job_card_data, steps]);

  const ButtonTestConnectionWithPermissions = WithAnyPermissionGroupTrue(
    ButtonTestConnection,
    ["global_super_user", "neura_ims_admin"]
  );

  if (
    isGetJobCardStateSuccess &&
    (job_card_data.state === "NONE" ||
      job_card_data.state === "REDO" ||
      job_card_data.state === "QEP")
  ) {
    return (
      <>
        <ConfirmReplaceUninstallDialog
          open={open}
          handleClose={handleClose}
          type={type}
        />
        <Helmet>
          <style>{"body { background: #FDB940; }"}</style>
        </Helmet>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 5 }}
        >
          <Grid
            item
            xs={2}
            justifyContent="flex-start"
            align="center"
            sx={{ ml: 2 }}
          >
            <LogOutDialog />
          </Grid>
          <Grid item xs={6} align="center" />

          <Grid item xs={2} justifyContent="flex-end">
            <NotificationIcon />
          </Grid>

          <Grid item xs={0.5} align="right" />

          <Typography
            style={{ fontSize: "40px", color: "white" }}
            sx={{ mt: 5, mb: 5 }}
          >
            Welcome
          </Typography>
          <Grid item xs={12} align="center">
            <Typography
              style={{ fontSize: "16px", color: "black" }}
              sx={{ mt: 5, mb: 5 }}
            >
              Installer name : {user_name}
            </Typography>
          </Grid>
          <Grid item xs={12} align="center"></Grid>

          <Grid item xs={1} />
          <Grid item xs={10} align="center">
            <Button
              style={theme.whiteButtonStyle.root}
              variant="outlined"
              onClick={handleNew}
            >
              New Installation
            </Button>
          </Grid>
          <Grid item xs={1} />

          <ButtonTestConnectionWithPermissions />

          <Grid item xs={1} />
          <Grid item xs={10} align="center" sx={{ mt: 1, mb: 1 }}>
            <Button
              style={theme.whiteButtonStyle.root}
              variant="outlined"
              onClick={handleReplacement}
            >
              Replacement
            </Button>
          </Grid>
          <Grid item xs={1} />

          <Grid item xs={1} />
          <Grid item xs={10} align="center">
            <Button
              sx={{ color: "#000" }}
              variant="text"
              onClick={handleUninstall}
            >
              Uninstall
            </Button>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
}

function ButtonTestConnection() {
  let navigate = useNavigate();

  return (
    <>
      <Grid item xs={1} />
      <Grid item xs={10} align="center">
        <Button
          style={theme.whiteButtonStyle.root}
          variant="outlined"
          onClick={() => navigate(`/installer/imsconnectiontest/`)}
          sx={{ mt: 1 }}
        >
          Test Connection
        </Button>
      </Grid>
      <Grid item xs={1} />
    </>
  );
}

function NotificationIcon() {
  let navigate = useNavigate();
  const { data: job_card_data, isSuccess: isGetJobCardDeclinedSuccess } =
    useGetDeclinedJobCardsQuery();
  if (isGetJobCardDeclinedSuccess) {
    const hasAlerts = job_card_data.job_cards.length > 0 ? true : false;

    return (
      <IconButton
        sx={{
          color: "#000",
          backgroundColor: "#FFE9C1",
          borderRadius: "50%",
          padding: "8px",
        }}
      >
        <Badge color="error" variant="dot" invisible={!hasAlerts}>
          <NotificationImportantOutlinedIcon
            fontSize="medium"
            onClick={() => navigate("/installer/alerts/")}
          />
        </Badge>
      </IconButton>
    );
  } else return <></>;
}
